
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ChartHeaderComponent extends Vue {
  @Prop({ type: String, required: false, default: '' }) title?: any;
  @Prop({ type: String, required: false, default: '' }) relabelKey?: any;
  @Prop({ type: Boolean, required: false, default: false }) isFullScreen?: any;
  @Prop({ type: String, required: false, default: '' }) event?: any;
  @Prop({ type: Boolean, default: false }) hideFullScreen!: boolean;
  @Prop({ type: Boolean, default: false }) showDataRelabel: boolean;
  @Prop({ type: Boolean, default: false }) isSignalHighlightSelected: boolean;
  @Prop({ type: Boolean, default: false }) hideFullscreenBtn: boolean;

  handleFullScreenClick() {
    this.$emit('toggle-fullscreen');
  }

  handleResetClick() {
    this.$emit('reset-chart', this.event);
  }
}
