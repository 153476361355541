import { render, staticRenderFns } from "./ChartHeader.vue?vue&type=template&id=05f27a52&scoped=true&"
import script from "./ChartHeader.vue?vue&type=script&lang=ts&"
export * from "./ChartHeader.vue?vue&type=script&lang=ts&"
import style0 from "./ChartHeader.vue?vue&type=style&index=0&id=05f27a52&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05f27a52",
  null
  
)

export default component.exports